// src/services/api/client.js
import { API_CONFIG, getFullUrl } from './config';

class ApiClient {
    constructor() {
        this.defaultHeaders = {
            'Content-Type': 'application/json'
        };
    }

    async request(endpoint, options = {}) {
        const url = getFullUrl(endpoint);
        const accessToken = localStorage.getItem('accessToken');
        
        if (accessToken) {
            options.headers = {
                ...options.headers,
                'Authorization': `Bearer ${accessToken}`
            };
        }

        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...this.defaultHeaders,
                    ...options.headers
                }
            });

            // Handle 401 Unauthorized
            if (response.status === 401) {
                const newToken = await this.handleTokenRefresh();
                if (newToken) {
                    // Retry the original request with new token
                    options.headers = {
                        ...options.headers,
                        'Authorization': `Bearer ${newToken}`
                    };
                    return this.request(endpoint, options);
                }
                // Clear auth and throw error instead of calling handleAuthError
                this.clearAuth();
                throw new Error('Authentication failed');
            }

            if (!response.ok) {
                throw await this.handleErrorResponse(response);
            }

            return await response.json();
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    async handleTokenRefresh() {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) return null;

        try {
            const response = await fetch(
                getFullUrl(API_CONFIG.ENDPOINTS.AUTH.REFRESH),
                {
                    method: 'POST',
                    headers: this.defaultHeaders,
                    body: JSON.stringify({ refresh: refreshToken })
                }
            );

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('accessToken', data.access);
                return data.access;
            }
            return null;
        } catch (error) {
            console.error('Token refresh failed:', error);
            return null;
        }
    }

    clearAuth() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        // Dispatch an event so the app can respond to auth clearing
        window.dispatchEvent(new CustomEvent('auth-cleared'));
    }

    async handleErrorResponse(response) {
        let errorMessage = `Error ${response.status}`;
        try {
            const errorData = await response.json();
            errorMessage = errorData.detail || errorData.message || errorMessage;
            console.log('Full error response:', errorData);
        } catch (e) {
            console.error('Raw response:', await response.text());
            console.error('Error parsing error response:', e);
        }
        return new Error(errorMessage);
    }
}

export const apiClient = new ApiClient();