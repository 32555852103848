// src/stores/auth.js
import { writable } from 'svelte/store';
import { authService } from '../services/api/auth';

// Create the initial store state
const createAuthStore = () => {
    const { subscribe, set, update } = writable({
        isAuthenticated: false,
        username: null,
        isLoading: false,
        error: null
    });

    return {
        subscribe,
        // Initialize the store with stored auth state
        init: () => {
            const { isAuthenticated, username } = authService.checkAuth();
            set({ isAuthenticated, username, isLoading: false, error: null });
        },
        // Login action
        login: async (username, password) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const response = await authService.login(username, password);
                set({
                    isAuthenticated: true,
                    username,
                    isLoading: false,
                    error: null
                });
                return response;
            } catch (error) {
                update(state => ({
                    ...state,
                    isLoading: false,
                    error: error.message
                }));
                throw error;
            }
        },
        // Register action
        register: async (username, password) => {
            update(state => ({ ...state, isLoading: true, error: null }));
            try {
                const response = await authService.register(username, password);
                set({
                    isAuthenticated: true,
                    username,
                    isLoading: false,
                    error: null
                });
                return response;
            } catch (error) {
                update(state => ({
                    ...state,
                    isLoading: false,
                    error: error.message
                }));
                throw error;
            }
        },
        // Logout action
        logout: async () => {
            update(state => ({ ...state, isLoading: true }));
            try {
                await authService.logout();
            } finally {
                set({
                    isAuthenticated: false,
                    username: null,
                    isLoading: false,
                    error: null
                });
            }
        },
        // Clear any errors
        clearError: () => {
            update(state => ({ ...state, error: null }));
        }
    };
};

export const auth = createAuthStore();

// Initialize the store when the app starts
auth.init();