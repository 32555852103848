<script>
  import Portfolio from './components/portfolio/Portfolio.svelte';
  import CreateWork from './components/create/CreateWork.svelte';
  import { auth } from './stores/auth';
  import { onMount } from 'svelte';

  let currentPage = 'create-work';

  function navigateToPortfolio() {
    currentPage = 'portfolio';
  }

  function openCreateWork() {
    currentPage = 'create-work';
  }

  onMount(() => {
    auth.init();
  });
</script>

<main class="app-container">
  {#if currentPage === 'create-work'}
    <div class="darkened-background">
      <CreateWork on:close={navigateToPortfolio} />
    </div>
  {:else}
    <div class="portfolio-wrapper">
      <Portfolio />
      <button class="fab" on:click={openCreateWork}>+</button>
    </div>
  {/if}
</main>

<style>
  /* Move global styles to app.css */
  .app-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: calc(16px + env(safe-area-inset-top)) 16px calc(16px + env(safe-area-inset-bottom)) 16px;
  }

  .portfolio-wrapper {
    flex: 1;
    position: relative;
  }

  .darkened-background {
    position: fixed;
    inset: 0;
    background-color: rgba(30, 30, 30, 0.9);
    display: grid;
    place-items: center;
    z-index: 1000;
  }

  .fab {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #2c3e50;
    color: white;
    display: grid;
    place-items: center;
    font-size: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border: none;
    z-index: 100;
  }
</style>