<script>
  import { annotations } from "../../media/ai/store/vision-store";


  annotations.subscribe((data) => {
  console.log('[Debug] Store annotations:', data);
});

  // Log whenever annotations are updated
  $: console.log('[Annotations Component] Current annotations:', $annotations);

  function approve(annotation) {
    annotation.status = 'approved';
    console.log('[Annotations Component] Approved annotation:', annotation);
    logCurrentAnnotations();
  }

  function reject(annotation) {
    annotation.status = 'rejected';
    console.log('[Annotations Component] Rejected annotation:', annotation);
    logCurrentAnnotations();
  }

  function logCurrentAnnotations() {
    console.log('[Annotations Component] Updated annotations:', $annotations);
  }
</script>

<ul>
  {#each $annotations as annotation (annotation.id)}
    <li>
      {annotation.content} - {annotation.status}
      <button on:click={() => approve(annotation)}>Approve</button>
      <button on:click={() => reject(annotation)}>Reject</button>
    </li>
  {/each}
</ul>
