<script>
  import { userInput, generatedSteps } from '../ai/store/state';


  let input = '';
  $: $userInput = input;
</script>

<div>
  <textarea
    bind:value={input}
    placeholder="What do you want to build?"
    rows="4"
    style="width: 100%; padding: 0.5rem; margin-bottom: 1rem;"
  ></textarea>

  <ul>
    {#each $generatedSteps as step}
      <li style="margin-bottom: 0.5rem;">
        {step.content} - {step.status}
        <button on:click={() => (step.status = 'approved')}>Approve</button>
        <button on:click={() => (step.status = 'rejected')}>Reject</button>
      </li>
    {/each}
  </ul>
</div>
