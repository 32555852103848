<script>
  import { userInput, generatedSteps } from '../ai/store/state';


  function generateMockSteps() {
    const prompt = $userInput;
    if (prompt) {
      $generatedSteps = [
        { id: '1', content: `Step 1: Plan your project for "${prompt}"`, status: 'pending' },
        { id: '2', content: `Step 2: Gather resources for "${prompt}"`, status: 'pending' },
        { id: '3', content: `Step 3: Execute the steps for "${prompt}"`, status: 'pending' },
      ];
    }
  }
</script>

<div>
  <button on:click={generateMockSteps} style="margin-right: 1rem;">Generate Steps</button>
</div>
