// media/ai/store/vision-store.ts
import { writable } from 'svelte/store';

export interface RawAnnotation {
    id: string;
    content: string;
    confidence: number;
    mediaType: 'image' | 'video';
    timestamp?: number;  // for video annotations
}

export const annotations = writable<RawAnnotation[]>([]);

// Debug subscriber
annotations.subscribe((value) => {
    console.log('[Vision Store] Annotations updated:', value);
});

// Export helper functions for mock data
export function createMockAnnotations(mediaType: 'image' | 'video'): RawAnnotation[] {
    if (mediaType === 'image') {
        return [
            {
                id: crypto.randomUUID(),
                content: "Foundation formwork installed with proper bracing",
                confidence: 0.95,
                mediaType: 'image'
            },
            {
                id: crypto.randomUUID(),
                content: "Rebar grid layout appears to be 16-inch spacing",
                confidence: 0.88,
                mediaType: 'image'
            },
            {
                id: crypto.randomUUID(),
                content: "Vapor barrier visible beneath rebar layout",
                confidence: 0.92,
                mediaType: 'image'
            }
        ];
    } else {
        return [
            {
                id: crypto.randomUUID(),
                content: "Wall framing technique demonstrates proper stud spacing",
                confidence: 0.92,
                mediaType: 'video',
                timestamp: 0
            },
            {
                id: crypto.randomUUID(),
                content: "Header installation for window opening in progress",
                confidence: 0.85,
                mediaType: 'video',
                timestamp: 15
            },
            {
                id: crypto.randomUUID(),
                content: "Sheathing attachment pattern follows code requirements",
                confidence: 0.89,
                mediaType: 'video',
                timestamp: 30
            }
        ];
    }
}