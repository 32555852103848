<script>
  import { annotations } from '../store/vision-store';
  import { generateMockAnnotations } from '../shared/utils';

  export let media;

  $: if (media) {
    console.log('[Processor] Media received:', media);
    console.log('[VideoProcessor] Processing media:', media);
    const mockAnnotations = generateMockAnnotations('video');
    console.log('[VideoProcessor] Generated annotations:', mockAnnotations);
    annotations.set(mockAnnotations);
  }
</script>
