<script>
  import MediaCapture from './media/capture/MediaCapture.svelte';
  import MediaPreview from './media/preview/MediaPreview.svelte';
  import Editor from './description/editor/Editor.svelte';
  import AIControls from './description/editor/AIControls.svelte';
  import Annotations from './description/editor/Annotations.svelte'; // Import Annotations component

  import './media/styles/media.css';
  import './description/styles/description.css';

  import { annotations } from './media/ai/store/vision-store';


  let capturedMedia = null;
  let mediaType = null;

  // Reactive update for annotations
  $: if (capturedMedia) {
    console.log('[Parent Component] Captured media:', capturedMedia);
  }

  $: if ($annotations.length > 0) {
    console.log('[Parent Component] Current annotations:', $annotations);
  }

  function handleCapture(event) {
    const { media, type } = event.detail;
    capturedMedia = media;
    mediaType = type;
  }

  function handleClear() {
    capturedMedia = null;
    mediaType = null;
  }

  function handleError(event) {
    console.error('[Parent Component] Media capture error:', event.detail);
  }
</script>

<style>
  .create-work-container {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 1rem;
    box-sizing: border-box;
    background: #f5f5f5;
  }

  .create-work-content {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .editor-section {
    margin-top: 2rem;
  }

  .annotations-section {
    margin-top: 2rem;
  }
</style>

<div class="create-work-container">
  <div class="create-work-content">
    <!-- Media Capture or Preview -->
    {#if !capturedMedia}
      <MediaCapture
        keepCameraActive={true}
        on:capture={handleCapture}
        on:error={handleError}
      />
    {:else}
      <MediaPreview
        media={capturedMedia}
        type={mediaType}
        on:clear={handleClear}
      />
    {/if}

    <!-- Editor and AI Controls -->
    <div class="editor-section">
      <Editor />
      <AIControls />
    </div>

    <!-- Annotations Section -->
    <div class="annotations-section">
      {#if $annotations.length > 0}
        <Annotations />
      {:else}
        <p>No annotations available yet.</p>
      {/if}
    </div>
  </div>
</div>
