// description/ai/store/state.ts
import { writable } from 'svelte/store';

export interface ReviewedAnnotation {
    id: string;
    content: string;
    status: 'approved' | 'rejected';
    sourceMediaType: 'image' | 'video';
    originalConfidence: number;
}

export interface Step {
    id: string;
    content: string;
    status: 'pending';
}

export const userInput = writable<string>('');
export const reviewedAnnotations = writable<ReviewedAnnotation[]>([]);
export const generatedSteps = writable<Step[]>([]);

// Debug subscribers
userInput.subscribe((value) => {
    console.log('[State Store] User input updated:', value);
});

reviewedAnnotations.subscribe((value) => {
    console.log('[State Store] Reviewed annotations updated:', value);
});

generatedSteps.subscribe((value) => {
    console.log('[State Store] Generated steps updated:', value);
});