<!-- MediaCapture.svelte -->
<script>
  import { createEventDispatcher, onMount, onDestroy } from 'svelte';
  import '../styles/media.css';
  
  const dispatch = createEventDispatcher();
  const MAX_RECORDING_SECONDS = 10;

  export let keepCameraActive = false;

  let videoElement;
  let stream = null;
  let isCameraReady = false;
  let isRecording = false;
  let mediaRecorder = null;
  let recordedChunks = [];
  let recordingTimer = null;
  let recordingDuration = 0;
  let error = '';

  let debugInfo = {
    streamActive: false,
    lastAction: '',
    recordingDuration: 0
  };

  onMount(async () => {
    if (keepCameraActive) {
      await startCamera();
    }
  });

  async function toggleCamera() {
    if (isCameraReady) {
      stopCamera();
    } else {
      await startCamera();
    }
  }

  async function startCamera() {
    try {
      if (!stream) {
        stream = await navigator.mediaDevices.getUserMedia({ 
          video: true,
          audio: true
        });
      }
      
      if (videoElement) {
        videoElement.srcObject = stream;
        debugInfo.streamActive = true;
        debugInfo.lastAction = 'Camera started';
        isCameraReady = true;
      }
    } catch (err) {
      error = `Camera error: ${err.message}`;
      debugInfo.lastAction = 'Camera failed to start';
      dispatch('error', error);
    }
  }

  function stopCamera() {
    if (stream && !keepCameraActive) {
      stream.getTracks().forEach(track => track.stop());
      stream = null;
    }
    if (videoElement) {
      videoElement.srcObject = null;
    }
    debugInfo.streamActive = false;
    debugInfo.lastAction = 'Camera stopped';
    isCameraReady = false;
  }

  function capturePhoto() {
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas.getContext('2d').drawImage(videoElement, 0, 0);
    const capturedImage = canvas.toDataURL('image/jpeg');
    dispatch('capture', { media: capturedImage, type: 'photo' });
    
    if (!keepCameraActive) {
      stopCamera();
    } else {
      videoElement.srcObject = null;
      isCameraReady = false;
    }
  }

  async function startRecording() {
    if (!stream) return;
    
    try {
      recordedChunks = [];
      let options = {};
      
      const mimeTypes = [
        'video/webm;codecs=vp8,opus',
        'video/webm;codecs=vp8',
        'video/webm',
        'video/mp4'
      ];
      
      for (const mimeType of mimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          options = { mimeType };
          break;
        }
      }
      
      mediaRecorder = new MediaRecorder(stream, options);
      
      mediaRecorder.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          recordedChunks.push(e.data);
        }
      };
      
      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const videoUrl = URL.createObjectURL(blob);
        dispatch('capture', { media: videoUrl, type: 'video' });
        recordingDuration = 0;
      };
      
      mediaRecorder.start(100);
      isRecording = true;
      recordingDuration = 0;
      debugInfo.lastAction = 'Started recording';
      
      recordingTimer = setInterval(() => {
        recordingDuration++;
        if (recordingDuration >= MAX_RECORDING_SECONDS) {
          stopRecording();
        }
      }, 1000);
    } catch (err) {
      error = `Failed to start recording: ${err.message}`;
      dispatch('error', error);
    }
  }

  function stopRecording() {
    if (mediaRecorder && isRecording) {
      clearInterval(recordingTimer);
      try {
        mediaRecorder.stop();
        debugInfo.lastAction = 'Stopping recording';
      } catch (err) {
        error = `Failed to stop recording: ${err.message}`;
        dispatch('error', error);
      }
      isRecording = false;
      if (!keepCameraActive) {
        stopCamera();
      } else {
        videoElement.srcObject = null;
        isCameraReady = false;
      }
    }
  }

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.match('image/*|video/mp4|video/webm|image/gif')) {
        error = 'Please upload only images (including GIFs) or videos';
        dispatch('error', error);
        return;
      }

      debugInfo.lastAction = `File selected: ${file.name}`;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch('capture', {
          media: reader.result,
          type: file.type.startsWith('image/') ? 'photo' : 'video'
        });
      };
      reader.readAsDataURL(file);
    }
  }

  onDestroy(() => {
    if (stream && !keepCameraActive) {
      stream.getTracks().forEach(track => track.stop());
    }
    if (recordingTimer) {
      clearInterval(recordingTimer);
    }
  });
</script>

<div class="media-container">
  <div class="media-debug-info">
    <div>Stream Active: {debugInfo.streamActive}</div>
    <div>Last Action: {debugInfo.lastAction}</div>
    {#if isRecording}
      <div>Recording: {recordingDuration}/{MAX_RECORDING_SECONDS}s</div>
    {/if}
  </div>

  {#if error}
    <div class="media-error">
      {error}
    </div>
  {/if}

  <div class="preview-area">
    <video
      bind:this={videoElement}
      autoplay
      playsinline
      muted
    />
    {#if debugInfo.streamActive}
      <div class="camera-active">
        {isRecording ? `Recording ${recordingDuration}s` : 'LIVE'}
      </div>
    {/if}
  </div>

  <div class="media-controls">
    <button
      class="media-button"
      on:click={toggleCamera}
      disabled={isRecording}
    >
      {isCameraReady ? 'Stop Camera' : 'Start Camera'}
    </button>
    
    <button
      class="media-button"
      on:click={capturePhoto}
      disabled={!isCameraReady || isRecording}
    >
      Take Photo
    </button>

    {#if isCameraReady && !isRecording}
      <button
        class="media-button"
        on:click={startRecording}
      >
        Record
      </button>
    {:else if isRecording}
      <button
        class="media-button recording"
        on:click={stopRecording}
      >
        Stop ({MAX_RECORDING_SECONDS - recordingDuration}s)
      </button>
    {/if}

    <label class="media-upload-label">
      Upload
      <input
        type="file"
        accept="image/*,.gif,video/mp4,video/webm"
        on:change={handleFileUpload}
        style="display: none;"
      />
    </label>
  </div>
</div>