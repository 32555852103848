// src/components/portfolio/Portfolio.svelte
<script>
  import { auth } from '../../stores/auth';
  import Login from '../auth/Login.svelte';
  import Register from '../auth/Register.svelte';

  let showMenu = false;
  let showPreferences = false;
  let showRegister = false;

  $: isAuthenticated = $auth.isAuthenticated;
  $: username = $auth.user?.username || '';

  function toggleMenu() {
    showMenu = !showMenu;
  }

  function toggleRegister() {
    showRegister = !showRegister;
  }

  async function logout() {
    await auth.logout();
    showMenu = false;
  }
</script>

<div class="portfolio">
  {#if isAuthenticated}
    <header class="portfolio-header">
      <h1>Your Portfolio, {username}</h1>
      <button class="menu-btn" on:click={toggleMenu}>
        {username}
      </button>

      {#if showMenu}
        <div class="menu-dropdown">
          <button on:click={() => showPreferences = !showPreferences}>
            Settings
          </button>
          <button on:click={logout}>
            Logout
          </button>
        </div>
      {/if}
    </header>

    <main class="portfolio-content">
      <!-- AI Summary Section -->
      <section class="ai-summary">
        <h2>Portfolio Insights</h2>
        <!-- AI summary content will go here -->
      </section>

      <!-- Work Grid Section -->
      <section class="work-grid">
        <!-- Work items will go here -->
      </section>
    </main>
  {:else}
    <div class="auth-container">
      {#if showRegister}
        <Register on:success={() => showRegister = false} />
        <button class="toggle-auth" on:click={toggleRegister}>
          Switch to Login
        </button>
      {:else}
        <Login on:success={() => isAuthenticated = true} />
        <button class="toggle-auth" on:click={toggleRegister}>
          Switch to Register
        </button>
      {/if}
    </div>
  {/if}
</div>

<style>
  .portfolio {
    width: 100%;
    min-height: 100vh;
    padding: 24px;
    background: #f0f0f0;
  }

  .portfolio-header {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 32px;
  }

  .menu-btn {
    background: transparent;
    color: #333;
    border: 1px solid #ddd;
    padding: 8px 16px;
    width: auto;
  }

  .menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 100;
  }

  .menu-dropdown button {
    width: 100%;
    text-align: left;
    background: none;
    color: #333;
    padding: 8px 16px;
  }

  .menu-dropdown button:hover {
    background: #f5f5f5;
  }

  .portfolio-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .auth-container {
    max-width: 400px;
    margin: 40px auto;
    padding: 24px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  .toggle-auth {
    width: 100%;
    margin-top: 16px;
    background: transparent;
    border: 1px solid #1a73e8;
    color: #1a73e8;
  }
</style>