// src/services/api/auth.js
import { API_CONFIG } from './config';
import { apiClient } from './client';

export const authService = {
    async login(username, password) {
        try {
            console.log('Login attempt:', { 
                endpoint: API_CONFIG.ENDPOINTS.AUTH.LOGIN,
                username,
                requestUrl: `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.AUTH.LOGIN}`
            });

            const response = await apiClient.request(API_CONFIG.ENDPOINTS.AUTH.LOGIN, {
                method: 'POST',
                body: JSON.stringify({ username, password })
            });

            console.log('Login response:', response);

            if (response.access) {
                localStorage.setItem('accessToken', response.access);
                localStorage.setItem('refreshToken', response.refresh);
                localStorage.setItem('username', username);
            } else {
                console.warn('Login response missing access token:', response);
            }

            return response;
        } catch (error) {
            console.error('Login failed:', {
                error,
                status: error.status,
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    },

    async register(username, password) {
        try {
            console.log('Registration attempt:', { 
                endpoint: API_CONFIG.ENDPOINTS.AUTH.REGISTER,
                username,
                requestUrl: `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.AUTH.REGISTER}`
            });

            const response = await apiClient.request(API_CONFIG.ENDPOINTS.AUTH.REGISTER, {
                method: 'POST',
                body: JSON.stringify({ username, password })
            });

            console.log('Registration response:', response);

            // If registration is successful, automatically log in
            if (response.success) {
                console.log('Registration successful, attempting login');
                return await this.login(username, password);
            }

            return response;
        } catch (error) {
            console.error('Registration failed:', {
                error,
                status: error.status,
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    },

    async logout() {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                console.log('Logout attempt:', { 
                    endpoint: API_CONFIG.ENDPOINTS.AUTH.LOGOUT,
                    requestUrl: `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.AUTH.LOGOUT}`
                });

                await apiClient.request(API_CONFIG.ENDPOINTS.AUTH.LOGOUT, {
                    method: 'POST',
                    body: JSON.stringify({ refresh: refreshToken })
                });
            } else {
                console.warn('No refresh token found during logout');
            }
        } catch (error) {
            console.error('Logout error:', {
                error,
                status: error.status,
                message: error.message,
                stack: error.stack
            });
        } finally {
            // Clear local storage regardless of server response
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('username');
            console.log('Local storage cleared during logout');
        }
    },

    checkAuth() {
        const token = localStorage.getItem('accessToken');
        const username = localStorage.getItem('username');
        const isAuthenticated = !!token;

        console.log('Auth check:', { 
            isAuthenticated, 
            hasToken: !!token,
            hasUsername: !!username 
        });

        return { isAuthenticated, username };
    }
};