<script>
  import ImageProcessor from './analyzers/ImageProcessor.svelte';
  import VideoProcessor from './analyzers/VideoProcessor.svelte';
  import { annotations } from './store/vision-store';

  export let media = null;
  export let mediaType = null;

  $: console.log('[VisionOrchestrator] Received props:', { media, mediaType });
  $: if (mediaType) console.log('[VisionOrchestrator] mediaType is valid:', mediaType);
</script>

<div>
  {#if mediaType === 'photo' || mediaType === 'image'}
    <p>[VisionOrchestrator] Routing to ImageProcessor</p>
    <ImageProcessor media={media} />
  {:else if mediaType === 'video'}
    <p>[VisionOrchestrator] Routing to VideoProcessor</p>
    <VideoProcessor media={media} />
  {:else}
    <p>[VisionOrchestrator] Unsupported media type: {mediaType}</p>
  {/if}
</div>
