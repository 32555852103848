// utils.ts
export function generateMockAnnotations(mediaType: string): Array<{ id: string; content: string; status: string }> {
    if (mediaType === 'image') {
      return [
        { id: '1', content: 'Detected: Wooden frame', status: 'pending' },
        { id: '2', content: 'Detected: Masonry wall', status: 'pending' },
      ];
    } else if (mediaType === 'video') {
      return [
        { id: '3', content: 'Detected: Construction site', status: 'pending' },
        { id: '4', content: 'Detected: Scaffolding', status: 'pending' },
      ];
    } else {
      return [{ id: '5', content: 'No annotations available', status: 'pending' }];
    }
  }
  