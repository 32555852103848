// src/services/api/config.js
console.log('API Base URL:', import.meta.env.VITE_API_URL);
export const API_CONFIG = {
    // Base URL will automatically switch based on environment
    BASE_URL: import.meta.env.VITE_API_URL,
    
    ENDPOINTS: {
        AUTH: {
            LOGIN: '/accounts/login/',
            REGISTER: '/accounts/register/',
            REFRESH: '/accounts/token/refresh/',
            LOGOUT: '/accounts/logout/'
        },
        PORTFOLIO: {
            LIST: '/accounts/portfolio/',
            CREATE: '/api/create/',
            UPDATE: (id) => `/api/works/${id}/`,
            DELETE: (id) => `/api/works/${id}/`,
            UPLOAD: '/api/upload/'
        },
        USER_SETTINGS: {
            PREFERENCES: '/user/preferences/',
            CAPABILITIES: '/user/capabilities/'
        }
    }
};

if (!API_CONFIG.BASE_URL) {
    throw new Error('API Base URL not configured. Please check your environment variables.');
}

export const getFullUrl = (endpoint) => `${API_CONFIG.BASE_URL}${endpoint}`;