<script>
  import { createEventDispatcher, onDestroy } from 'svelte';
  import VisionOrchestrator from '../ai/VisionOrchestrator.svelte';
  import '../styles/media.css';

  export let media = null;
  export let type = null; // 'photo' or 'video'

  const dispatch = createEventDispatcher();
  let mediaPreviewElement;
  let showVisionOrchestrator = false;

  function clearMedia() {
    console.log('[MediaPreview] Clearing media...');
    if (mediaPreviewElement) {
      mediaPreviewElement.pause();
      mediaPreviewElement.removeAttribute('src');
      mediaPreviewElement.load();
    }

    if (type === 'video' && media?.startsWith('blob:')) {
      URL.revokeObjectURL(media);
    }

    dispatch('clear');
  }

  function processMedia() {
    console.log('[MediaPreview] Processing media:', { media, type });
    showVisionOrchestrator = true;
  }

  onDestroy(() => {
    if (type === 'video' && media?.startsWith('blob:')) {
      URL.revokeObjectURL(media);
    }
  });
</script>

<div class="media-container">
  <div class="preview-area">
    {#if type === 'photo'}
      <img
        src={media}
        alt="Captured media"
        class="captured-image"
      />
    {:else if type === 'video'}
      <video 
        bind:this={mediaPreviewElement}
        class="captured-video"
        src={media}
        autoplay
        loop
        muted
        playsinline
        controls
      >
        <track kind="captions" />
      </video>
    {/if}
  </div>

  <div class="media-controls">
    <button
      class="media-button"
      on:click={clearMedia}
    >
      Clear
    </button>
    <button
      class="media-button"
      on:click={processMedia}
    >
      Process Media
    </button>
  </div>

  {#if showVisionOrchestrator}
    <VisionOrchestrator media={media} mediaType={type} />
  {/if}
</div>
