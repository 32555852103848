// src/components/auth/Login.svelte
<script>
  import { auth } from '../../stores/auth';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  let username = '';
  let password = '';

  async function handleSubmit() {
    try {
      await auth.login(username, password);
      // Clear form
      username = '';
      password = '';
      dispatch('success');
    } catch (error) {
      // Error is automatically handled by the store
      console.error('Login error:', error);
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} class="auth-form">
  <h2>Login</h2>
  <div class="form-group">
    <label for="username">Username</label>
    <input
      type="text"
      id="username"
      bind:value={username}
      placeholder="Enter username"
      required
    />
  </div>
  <div class="form-group">
    <label for="password">Password</label>
    <input
      type="password"
      id="password"
      bind:value={password}
      placeholder="Enter password"
      required
    />
  </div>
  {#if $auth.error}
    <div class="error-message">
      {$auth.error}
    </div>
  {/if}
  <button type="submit" disabled={$auth.isLoading}>
    {$auth.isLoading ? 'Logging in...' : 'Login'}
  </button>
</form>

<style>
  .auth-form {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .form-group {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .error-message {
    color: #dc3545;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: #f8d7da;
    border-radius: 4px;
  }
</style>